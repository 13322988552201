<template>
  <div>
    <div class="px-10 pt-10 grid place-items-center">
      <img :src="require('@/assets/images/logo.svg')" class="w-1/4 pt-8" />
      <lottie-animation
        path="lotties/404.json"
        :loop="true"
        :autoPlay="true"
        :speed="1"
        :height="200"
      />
    </div>
    <p class="text-4xl text-center font-ZuumeRegular mt-10">
      Oops! The page you are looking for is not found!
    </p>
    <p class="text-xl text-center font-ZuumeRegular"></p>
  </div>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default {
  components: { LottieAnimation },
};
</script>

<style scoped></style>
