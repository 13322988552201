<template>
  <div
    class="loader w-screen fixed top-0 left-0 flex justify-center items-center z-50"
  >
    <img :src="require('@/assets/images/loader.gif')" class="w-48 h-48" />
  </div>
</template>

<script>
export default {
  name: "Page Loader",
};
</script>

<style scoped lang="postcss">
.loader {
  @apply bg-nColorYellow;
  height: 100dvh;
}
</style>
