import { createRouter, createWebHistory } from "vue-router";
import NotFound from "../views/NotFound.vue";
import BaseRoutes from "./BaseRoutes";

const routes = [
  ...BaseRoutes,
  {
    path: "/maintenance",
    name: "Maintenance",

    component: () =>
      import(/* webpackChunkName: "maintenance" */ "../views/Maintenance.vue"),
  },
  {
    path: "/:catchAll(.*)*/d0993d95",
    name: "NotFound",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
