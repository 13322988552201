export default [
  {
    path: "/",
    name: "Home",
    meta: {
      requiresAuth: false,
    },
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/Home.vue"),
  },
  {
    path: "/:business/d0993d95",
    name: "Leaderboard",
    meta: {
      requiresAuth: false,
    },
    component: () =>
      import(/* webpackChunkName: "leaderboard" */ "@/views/Leaderboard.vue"),
  },
];
