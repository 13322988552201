<template>
  <div>
    <Loader v-if="isPageLoading" />
    <router-view v-else :key="$route.fullPath" />
  </div>
</template>
<script>
import Loader from "@/components/elements/Loader.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Loader,
  },
  created() {},
  mounted() {},
  beforeUnmount() {},
  data() {
    return {};
  },
  methods: {},
  computed: { ...mapGetters(["isPageLoading"]) },
  watch: {},
};
</script>
<style>
body,
html {
  background-color: #fcfaf7;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
