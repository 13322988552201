import { useToast } from "vue-toastification";

const toast = useToast();

export default {
  state: {
    maintenanceError: null,
  },
  getters: {
    maintenanceError(state) {
      return state.maintenanceError;
    },
  },
  mutations: {
    setMaintenanceError(state, data) {
      state.maintenanceError = data;
    },
  },
  actions: {
    setError({ commit }, payload) {
      commit("setMaintenanceError", payload);
      toast.error(payload, { timeout: false });
    },
  },
};